//@ts-nocheck
import axios from 'axios';

const PATH_URL = '/login';

function getUrl(): string {
  return process.env.REACT_APP_API_URL + PATH_URL;
}

export type AuthLoginRequest = {
  'login': string,
  'password': string
}

export type AuthLoginPostResponseOk = {
  'status': number,
  'message': AuthLoginPostResponseMessageOk,
}

export type AuthLoginPostResponseMessageOk = {
  data: {
    apikey: string
  },
}

export type AuthLoginPostResponseError = {
  'status': number,
  'message': AuthLoginPostResponseMessageError,
}

export type AuthLoginPostResponseMessageError = string

export default async function login(request: AuthLoginRequest): Promise<AuthLoginPostResponseOk | AuthLoginPostResponseError> {

  return axios.post(getUrl(), request, {
    // здесь не нужно формировать заголовок с api-Ключом
  }).then(r => {
    return <AuthLoginPostResponseOk | AuthLoginPostResponseError>r.data;
  }).catch(e => {
    const resp: AuthLoginPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: AuthLoginPostResponseOk | AuthLoginPostResponseError): boolean {
  return typeof response.message === 'object';
}