//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { AdSlots } from './ad-slot.daily-limit.patch';

const PATH_URL = '/ad-slot/remove-ignore-ai';

export type AdSlotRemoveIgnoreAIPatchRequest = {
  adSlots: AdSlots[]
}

export type AdSlots = {
  tagid: string
  id: number
  domainId: number
  systemId: number
  app: 1 | 0,
}

export type AdSlotRemoveIgnoreAIPatchResponseOk = {
  'status': number,
  'message': AdSlotRemoveIgnoreAIPatchResponseMessageOk,
}

export type AdSlotRemoveIgnoreAIPatchResponseMessageOk = {
  data: null,
}

export type AdSlotRemoveIgnoreAIPatchResponseError = {
  'status': number,
  'message': AdSlotRemoveIgnoreAIPatchResponseMessageError,
}

export type AdSlotRemoveIgnoreAIPatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();


export default function patchRemoveIgnoreAI(request: AdSlotRemoveIgnoreAIPatchRequest): Promise<AdSlotRemoveIgnoreAIPatchResponseOk | AdSlotRemoveIgnoreAIPatchResponseError> {
  return axios.patch(process.env.REACT_APP_API_URL + PATH_URL, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AdSlotRemoveIgnoreAIPatchResponseMessageOk | AdSlotRemoveIgnoreAIPatchResponseError>r.data;
  }).catch(e => {
    const resp: AdSlotRemoveIgnoreAIPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseRemoveIgnoreAIOK(response: AdSlotRemoveIgnoreAIPatchResponseOk | AdSlotRemoveIgnoreAIPatchResponseError): boolean {
  return typeof response.message !== 'string';
}