//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate, getDateNDaysAgo } from '../../utils/date';
import { DateTime } from 'luxon';

const PATH_URL = '/campaigns/{id}/realtime-blacklist';

export type CampaignRealtimeBlacklistRequest = {
  startDate?: string
  endDate?: string,
  inIgnoreList?: 1,
  minConvs?: number,
  maxConvs?: number,
  minSpend?: number,
  maxSpend?: number,
  cpa30?: number,
  cpa7?: number,
  minCpa?: number,
  maxCpa?: number,
  minCpc?: number,
  maxCpc?: number,
  regions?: number[],
  systems?: number[],
  formats?: string[],
  sort?: string,
  dir?: string,
  page?: number,
  results?: number,
}

export function createCampaignRealtimeBlacklistRequest(): CampaignRealtimeBlacklistRequest {
  const date = new Date();
  return { page: 1, results: 25, startDate: formatDate(date), endDate: formatDate(date) };
}

export function recognizeCampaignRealtimeBlacklistRequest(): CampaignRealtimeBlacklistRequest {
  const dateStr = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  const r = { page: 1, results: 25, startDate: dateStr, endDate: dateStr };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createCampaignRealtimeBlacklistRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    if(key === 'startDate' || key === 'endDate') {
      r[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
      if (r[key] === 'Invalid DateTime') {
        r[key] = DateTime.fromFormat(params[key], 'yyyy-M-dd').toFormat('yyyy-MM-dd');
      }
      if (r[key] === 'Invalid DateTime') {
        r[key] = dateStr;
      }
      continue;
    }
    // @ts-ignore
    r[key] = params[key];
  }
  r.page = Number(r.page);
  r.results = Number(r.results);
  return r;
}

export type CampaignRealtimeBlacklistResponseOk = {
  'status': number,
  'message': CampaignRealtimeBlacklistResponseMessageOk,
}

export type CampaignRealtimeBlacklistResponseMessageOk = {
  headers: string[],
  campaign: CampaignRealtimeBlacklist,
  data: CampaignRealtimeBlacklistStat[],
}

export type CampaignRealtimeBlacklist = {
  id: number,
  name: string,
  rate: number,
  desiredCpa: number,
}


export type CampaignRealtimeBlacklistResponseError = {
  'status': number,
  'message': CampaignRealtimeBlacklistResponseMessageError,
}

export type CampaignRealtimeBlacklistResponseMessageError = string


export type CampaignRealtimeBlacklistStat = {
  'isApp': boolean,
  'list': 'none' | 'white' | 'black',
  'banned': string,
  'systemId': number,
  'system': string,
  'publisher': string,
  'domainId': number,
  'groupId': number,
  'domain': string,
  'tagid': string,
  'reqs': number,
  'hits': number,
  'hitreal': number,
  'clicks': number,
  'ctr': number,
  'variance': undefined | {
    'reqs': number,
    'hitreal': number,
    'ctr': number,
    'winRateProc': number,
    'cpm': number
    'cpa': number
  },
  'viewProc': number,
  'winRateProc': number,
  'spent': number,
  'cpm': number,
  'leads': number,
  'cr': string,
  'crProc': number,
  'cpa': number,
  'cpc': number,
  'limitSpent': number,
  'bidfloor': {
    'min': number|string,
    'max': number|string
  },
  'appBundle': string,
  'convCost': number,
  'rate': number,
  'ignore': {
    'val': boolean,
    'comment': string
  },
  'ignoreAi': {
    'val': boolean,
    'comment': string
  }
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id: number, request: CampaignRealtimeBlacklistRequest = null): Promise<CampaignRealtimeBlacklistResponseOk | CampaignRealtimeBlacklistResponseError> {
  return axios.get(getUrl(id), {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignRealtimeBlacklistResponseOk | CampaignRealtimeBlacklistResponseError>r.data;
  }).catch(e => {
    const resp: CampaignRealtimeBlacklistResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignRealtimeBlacklistResponseOk | CampaignRealtimeBlacklistResponseError): boolean {
  return typeof response.message === 'object';
}