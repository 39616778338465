//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { AdSlots } from './ad-slot.daily-limit.patch';

const PATH_URL = '/ad-slot/whitelist';

export type AdSlotWhitelistPatchRequest = {
  adSlots: AdSlots[]
}

export type AdSlots = {
  tagid: string
  id: number
  domainId: number
  systemId: number
  app: 1 | 0,
}

export type AdSlotWhitelistPatchResponseOk = {
  'status': number,
  'message': AdSlotWhitelistPatchResponseMessageOk,
}

export type AdSlotWhitelistPatchResponseMessageOk = {
  data: null,
}

export type AdSlotWhitelistPatchResponseError = {
  'status': number,
  'message': AdSlotWhitelistPatchResponseMessageError,
}

export type AdSlotWhitelistPatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();


export default function patchUpdateWhitelist(request: AdSlotWhitelistPatchRequest): Promise<AdSlotWhitelistPatchResponseOk | AdSlotWhitelistPatchResponseError> {
  return axios.patch(process.env.REACT_APP_API_URL + PATH_URL, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AdSlotWhitelistPatchResponseMessageOk | AdSlotWhitelistPatchResponseError>r.data;
  }).catch(e => {
    const resp: AdSlotWhitelistPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseWhitelistOK(response: AdSlotWhitelistPatchResponseOk | AdSlotWhitelistPatchResponseError): boolean {
  return typeof response.message !== 'string';
}