//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatServerDateYmd } from '../../utils/date';
import { AlertsRequest } from '../eva/alerts/alert.get';

const PATH_URL = '/offers';

export type OfferRequest = {
  filteredUser?: string
}

export function createOfferRequest(): OfferRequest {
  return {  };
}

export function recognizeOfferRequest(): OfferRequest {
  const r = {  };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createOfferRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type OffersResponseOk = {
  'status': number,
  'message': OffersResponseMessageOk,
}

export type OffersData = {
  id: number,
  name: string,
  thresholdConv: number,
  url: UrlData[],
  domain: DomainData,
  user: UserData,
  country: CountryData,
  category: CategoryData,
  gmt: number,
  dailyLimit: number,
  checkDayLimit: boolean,
  problem: string[]
}

export type UrlData = {
  id: number,
  url: string,
  offer_id: number
}

export type DomainData = {
  domain_id: number,
  name: string
}

export type UserData = {
  user_id: number,
  login: string
}

export type CountryData = {
  cc: string,
  name: string
}

export type CategoryData = {
  cat_id: number,
  name: string
}

export type CountriesData = {
  value: string,
  option: string
}

export type CategoriesData = {
  id: number,
  name: string,
  info: string
}

export type DomainsData = {
  id: number,
  name: string
}

export type UsersData = {
  login: string
}

export type OffersResponseMessageOk = {
  data: OffersData[],
  countries: CountriesData[],
  categories: CategoriesData[],
  domains: DomainsData[],
  users: UsersData[],
  viewOfferCreator: boolean
}

export type OffersResponseError = {
  'status': number,
  'message': OffersResponseMessageError,
}

export type OffersResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function get(request: OfferRequest): Promise<OffersResponseOk | OffersResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <OffersResponseOk | OffersResponseError>r.data;
  }).catch(e => {
    const resp: OffersResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: OffersResponseOk | OffersResponseError): boolean {
  return typeof response.message === 'object';
}