import { Campaign, CampaignResponseMessageOk } from '../../api/campaigns/campaigns.get';
import React from 'react';
import 'rc-switch/assets/index.css';
import {
  CampaignRealtimeBlacklistResponseMessageOk,
  CampaignRealtimeBlacklistStat,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';

export enum DataColumnsRealtimeBlacklist {
  TYPE,
  SYSTEM,
  PUBLISHER,
  DOMAIN_ID,
  DOMAIN,
  TAG,
  APP_BUNDLE,
  POS,
  PAGE_ID,
  PAGE,
  WORD,
  IMPS,
  // IMPS_VARIANCE,
  HITS,
  HITREAL,
  // HITREAL_VARIANCE,
  CLICKS,
  VIEWS_PROC,
  CTR,
  // CTR_VARIANCE,
  VTR,
  // VTR_VARIANCE,
  LIMIT_SPENT,
  COSTS,
  CPM,
  // CPM_VARIANCE,
  CPC,
  PDP,
  CR,
  CR_PROC,
  CPA,
  // CPA_VARIANCE,
  BIDFLOOR_INTERVAL,
  CONV_COST,
  KOEF,
  RATE,
  LISTS,
  HELPER,
  INSTALL,
  REG,
  TRADER,
  DEPOSIT,
  DEAL,
  MIN,
}

export namespace DataColumnsRealtimeBlacklist {
  export function getAllInString(): string[] {
    return [
      'col_type',
      'col_system',
      'col_publisher',
      'col_domain_id',
      'col_domain',
      'col_tag',
      'col_app_bundle',
      'col_pos',
      'col_page_id',
      'col_page',
      'col_word',
      'col_imps',
      // 'col_imps_variance',
      'col_hits',
      'col_hitreal',
      // 'col_hitreal_variance',
      // 'col_clicks',
      'col_views_proc',
      'col_ctr',
      // 'col_ctr_variance',
      // 'col_vtr',
      // 'col_vtr_variance',
      // 'col_limit_spent',
      'col_costs',
      'col_cpm',
      // 'col_cpm_variance',
      // 'col_cpc',
      'col_pdp',
      'col_cr',
      'col_cr_proc',
      'col_cpa',
      // 'col_cpa_variance',
      // 'col_bidfloor_interval',
      'col_conv_cost',
      'col_koef',
      'col_rate',
      'col_lists',
      'col_helper',
      'col_install',
      'col_reg',
      'col_trader',
      'col_deposit',
      'col_deal',

    ];
  }

  export function getSortAssociations(): { [key: number]: string; } {
    {
      return {
        [DataColumnsRealtimeBlacklist.COSTS]: 'spend',
        [DataColumnsRealtimeBlacklist.IMPS]: 'reqs',
        [DataColumnsRealtimeBlacklist.HITS]: 'hits',
        [DataColumnsRealtimeBlacklist.HITREAL]: 'hitreal',
        [DataColumnsRealtimeBlacklist.CLICKS]: 'clicks',
        [DataColumnsRealtimeBlacklist.CPA]: 'cpa',
        [DataColumnsRealtimeBlacklist.RATE]: 'rate',
        [DataColumnsRealtimeBlacklist.LISTS]: 'lists',
        [DataColumnsRealtimeBlacklist.PDP]: 'convs',
        [DataColumnsRealtimeBlacklist.CPM]: 'cpm',
        [DataColumnsRealtimeBlacklist.CPC]: 'cpc',
        [DataColumnsRealtimeBlacklist.CTR]: 'ctr',
      };
    }
  }

  export function getName(column: DataColumnsRealtimeBlacklist): string {
    switch (column) {
      case DataColumnsRealtimeBlacklist.TYPE:
        return 'Тип';
      case DataColumnsRealtimeBlacklist.SYSTEM:
        return 'Система';
      case DataColumnsRealtimeBlacklist.PUBLISHER:
        return 'Паблишер';
      case DataColumnsRealtimeBlacklist.DOMAIN_ID:
        return 'Domain id';
      case DataColumnsRealtimeBlacklist.DOMAIN:
        return 'Domain';
      case DataColumnsRealtimeBlacklist.TAG:
        return 'Tagid';
      case DataColumnsRealtimeBlacklist.POS:
        return 'Позиция';
      case DataColumnsRealtimeBlacklist.PAGE_ID:
        return 'Page id';
      case DataColumnsRealtimeBlacklist.PAGE:
        return 'Page';
      case DataColumnsRealtimeBlacklist.WORD:
        return 'Word';
      case DataColumnsRealtimeBlacklist.IMPS:
        return 'Запросы';
      // case DataColumnsRealtimeBlacklist.IMPS_VARIANCE:
      //   return 'Запросы - отклонение';
      case DataColumnsRealtimeBlacklist.HITS:
        return 'Вины';
      case DataColumnsRealtimeBlacklist.HITREAL:
        return 'Показы';
      // case DataColumnsRealtimeBlacklist.HITREAL_VARIANCE:
      //   return 'Показы - отклонение';
      case DataColumnsRealtimeBlacklist.CLICKS:
        return 'Клики';
      case DataColumnsRealtimeBlacklist.VIEWS_PROC:
        return 'Views, %';
      case DataColumnsRealtimeBlacklist.CTR:
        return 'CTR';
      // case DataColumnsRealtimeBlacklist.CTR_VARIANCE:
      //   return 'CTR - отклонение';
      case DataColumnsRealtimeBlacklist.VTR:
        return 'Win rate';
      // case DataColumnsRealtimeBlacklist.VTR_VARIANCE:
      //   return 'Win rate - отклонение';
      case DataColumnsRealtimeBlacklist.LIMIT_SPENT:
        return 'Дейли Бюджет';
      case DataColumnsRealtimeBlacklist.COSTS:
        return 'Потрачено';
      case DataColumnsRealtimeBlacklist.CPM:
        return 'CPM';
      // case DataColumnsRealtimeBlacklist.CPM_VARIANCE:
      //   return 'CPM - отклонение';
      case DataColumnsRealtimeBlacklist.CPC:
        return 'CPC';
      case DataColumnsRealtimeBlacklist.PDP:
        return 'Лиды';
      case DataColumnsRealtimeBlacklist.CR:
        return 'Cr';
      case DataColumnsRealtimeBlacklist.CR_PROC:
        return 'CR, %';
      case DataColumnsRealtimeBlacklist.CPA:
        return 'CPA';
      // case DataColumnsRealtimeBlacklist.CPA_VARIANCE:
      //   return 'CPA - отклонение';
      case DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL:
        return 'Интервал бидфлора';
      case DataColumnsRealtimeBlacklist.CONV_COST:
        return 'Профит лида';
      case DataColumnsRealtimeBlacklist.KOEF:
        return 'Коэффициент';
      case DataColumnsRealtimeBlacklist.RATE:
        return 'Ставка';
      case DataColumnsRealtimeBlacklist.LISTS:
        return 'B/W';
      case DataColumnsRealtimeBlacklist.HELPER:
        return 'Хелпер';
      case DataColumnsRealtimeBlacklist.INSTALL:
        return 'Инсталы';
      case DataColumnsRealtimeBlacklist.REG:
        return 'Регистрация';
      case DataColumnsRealtimeBlacklist.TRADER:
        return 'Трейдер';
      case DataColumnsRealtimeBlacklist.DEPOSIT:
        return 'Депозит';
      case DataColumnsRealtimeBlacklist.DEAL:
        return 'Deal';
      case DataColumnsRealtimeBlacklist.MIN:
        return 'Поминутка';
      case DataColumnsRealtimeBlacklist.APP_BUNDLE:
        return 'App Bundle';
      default:
        return '';
    }
  }

  export function sortBy(data: CampaignRealtimeBlacklistStat[], column: DataColumnsRealtimeBlacklist, dir: 'asc' | 'desc'): CampaignRealtimeBlacklistStat[] {
    return data.sort((a, b) => {
      let key: keyof CampaignRealtimeBlacklistStat;
      switch (column) {
        case DataColumnsRealtimeBlacklist.TYPE:
          key = 'isApp';
          break;
        case DataColumnsRealtimeBlacklist.SYSTEM:
          key = 'system';
          break;
        case DataColumnsRealtimeBlacklist.PUBLISHER:
          key = 'publisher';
          break;
        case DataColumnsRealtimeBlacklist.DOMAIN_ID:
          key = 'domainId';
          break;
        case DataColumnsRealtimeBlacklist.DOMAIN:
          key = 'domain';
          break;
        case DataColumnsRealtimeBlacklist.TAG:
          key = 'tagid';
          break;
        case DataColumnsRealtimeBlacklist.IMPS:
          key = 'reqs';
          break;
        case DataColumnsRealtimeBlacklist.HITS:
          key = 'hits';
          break;
        case DataColumnsRealtimeBlacklist.HITREAL:
          key = 'hitreal';
          break;
        case DataColumnsRealtimeBlacklist.CLICKS:
          key = 'clicks';
          break;
        case DataColumnsRealtimeBlacklist.VIEWS_PROC:
          key = 'viewProc';
          break;
        case DataColumnsRealtimeBlacklist.CTR:
          key = 'ctr';
          break;
        case DataColumnsRealtimeBlacklist.VTR:
          key = 'winRateProc';
          break;
        case DataColumnsRealtimeBlacklist.LIMIT_SPENT:
          key = 'limitSpent';
          break;
        case DataColumnsRealtimeBlacklist.COSTS:
          key = 'spent';
          break;
        case DataColumnsRealtimeBlacklist.CPM:
          key = 'cpm';
          break;
        case DataColumnsRealtimeBlacklist.CPC:
          key = 'cpc';
          break;
        case DataColumnsRealtimeBlacklist.PDP:
          key = 'leads';
          break;
        case DataColumnsRealtimeBlacklist.CR:
          key = 'cr';
          break;
        case DataColumnsRealtimeBlacklist.CR_PROC:
          key = 'crProc';
          break;
        case DataColumnsRealtimeBlacklist.CPA:
          key = 'cpa';
          break;
        case DataColumnsRealtimeBlacklist.CONV_COST:
          key = 'convCost';
          break;
        case DataColumnsRealtimeBlacklist.KOEF:
          key = 'rate';
          break;
        case DataColumnsRealtimeBlacklist.RATE:
          key = 'cr';
          break;
        case DataColumnsRealtimeBlacklist.LISTS:
          key = 'list';
          break;
        case DataColumnsRealtimeBlacklist.APP_BUNDLE:
          key = 'appBundle';
          break;
        default:
          key = 'domainId';
      }

      if (dir === 'asc')
        return a[key] > b[key] ? 1 : -1;
      else
        return a[key] < b[key] ? 1 : -1;
    });
  }

  export function getSelector(column: DataColumnsRealtimeBlacklist, row: CampaignRealtimeBlacklistStat): any {
    const isTotal = isTotalRow(row);
    switch (column) {
      case DataColumnsRealtimeBlacklist.TYPE:
        return row.isApp ? 'app' : '';
      case DataColumnsRealtimeBlacklist.SYSTEM:
        return row.system;
      case DataColumnsRealtimeBlacklist.PUBLISHER:
        return row.publisher;
      case DataColumnsRealtimeBlacklist.DOMAIN_ID:
        return row.domainId;
      case DataColumnsRealtimeBlacklist.DOMAIN:
        return row.domain;
      case DataColumnsRealtimeBlacklist.TAG:
        return row.tagid;
      case DataColumnsRealtimeBlacklist.IMPS:
        return row.reqs;
      case DataColumnsRealtimeBlacklist.HITS:
        return row.hits;
      case DataColumnsRealtimeBlacklist.HITREAL:
        return row.hitreal;
      case DataColumnsRealtimeBlacklist.CLICKS:
        return row.clicks;
      case DataColumnsRealtimeBlacklist.VIEWS_PROC:
        return row.viewProc;
      case DataColumnsRealtimeBlacklist.CTR:
        return row.ctr;
      case DataColumnsRealtimeBlacklist.VTR:
        return row.winRateProc;
      case DataColumnsRealtimeBlacklist.LIMIT_SPENT:
        return row.limitSpent;
      case DataColumnsRealtimeBlacklist.COSTS:
        return row.spent;
      case DataColumnsRealtimeBlacklist.CPM:
        return row.cpm;
      case DataColumnsRealtimeBlacklist.CPC:
        return row.cpc;
      case DataColumnsRealtimeBlacklist.PDP:
        return row.leads;
      case DataColumnsRealtimeBlacklist.CR:
        return row.cr;
      case DataColumnsRealtimeBlacklist.CR_PROC:
        return row.crProc;
      case DataColumnsRealtimeBlacklist.CPA:
        return row.cpa;
      case DataColumnsRealtimeBlacklist.CONV_COST:
        return row.convCost;
      case DataColumnsRealtimeBlacklist.KOEF:
        return row.rate;
      case DataColumnsRealtimeBlacklist.RATE:
        return row.cr;
      case DataColumnsRealtimeBlacklist.LISTS:
        return row.list;
      case DataColumnsRealtimeBlacklist.POS:
        return '';
      case DataColumnsRealtimeBlacklist.PAGE_ID:
        return '';
      case DataColumnsRealtimeBlacklist.PAGE:
        return '';
      case DataColumnsRealtimeBlacklist.WORD:
        return '';
      // case DataColumnsRealtimeBlacklist.IMPS_VARIANCE:
      //   return row.variance?.reqs ?? '';
      // case DataColumnsRealtimeBlacklist.HITREAL_VARIANCE:
      //   return row.variance?.hitreal ?? '';
      // case DataColumnsRealtimeBlacklist.CTR_VARIANCE:
      //   return row.variance?.ctr ?? '';
      // case DataColumnsRealtimeBlacklist.VTR_VARIANCE:
      //   return row.variance?.winRateProc ?? '';
      // case DataColumnsRealtimeBlacklist.CPM_VARIANCE:
      //   return row.variance?.cpm ?? '';
      // case DataColumnsRealtimeBlacklist.CPA_VARIANCE:
      //   return row.variance?.cpa ?? '';
      case DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL:
        return row.bidfloor.min + ' - ' + row.bidfloor.max;
      case DataColumnsRealtimeBlacklist.HELPER:
        return '';
      case DataColumnsRealtimeBlacklist.INSTALL:
        return '';
      case DataColumnsRealtimeBlacklist.REG:
        return '';
      case DataColumnsRealtimeBlacklist.TRADER:
        return '';
      case DataColumnsRealtimeBlacklist.DEPOSIT:
        return '';
      case DataColumnsRealtimeBlacklist.DEAL:
        return '';
      case DataColumnsRealtimeBlacklist.MIN:
        return 'Поминутка';
      case DataColumnsRealtimeBlacklist.APP_BUNDLE:
        return row.appBundle;
      default:
        return '';
    }
  }

  export function getFromResponse(message: CampaignRealtimeBlacklistResponseMessageOk): DataColumnsRealtimeBlacklist[] {
    let columns: DataColumnsRealtimeBlacklist[] = [];
    let headers = DataColumnsRealtimeBlacklist.getAllInString();
    if (message.headers.length > 0) {
      headers = message.headers;
    }

    headers.map((header) => {
      const column = get(header);
      if (typeof column === 'number') {
        columns.push(column);
      }
    });


    columns.sort((a, b) => {
      return a > b ? 1 : -1;
    });
    return columns;
  }

  export function get(header: string): DataColumnsRealtimeBlacklist | null {
    switch (header) {
      case 'col_type':
        return DataColumnsRealtimeBlacklist.TYPE;
      case 'col_system':
        return DataColumnsRealtimeBlacklist.SYSTEM;
      case 'col_publisher':
        return DataColumnsRealtimeBlacklist.PUBLISHER;
      case 'col_domain_id':
        return DataColumnsRealtimeBlacklist.DOMAIN_ID;
      case 'col_domain':
        return DataColumnsRealtimeBlacklist.DOMAIN;
      case 'col_tag':
        return DataColumnsRealtimeBlacklist.TAG;
      case 'col_pos':
        return DataColumnsRealtimeBlacklist.POS;
      case 'col_page_id':
        return DataColumnsRealtimeBlacklist.PAGE_ID;
      case 'col_page':
        return DataColumnsRealtimeBlacklist.PAGE;
      case 'col_word':
        return DataColumnsRealtimeBlacklist.WORD;
      case 'col_imps':
        return DataColumnsRealtimeBlacklist.IMPS;
      // case 'col_imps_variance':
      //   return DataColumnsRealtimeBlacklist.IMPS_VARIANCE;
      case 'col_hits':
        return DataColumnsRealtimeBlacklist.HITS;
      case 'col_hitreal':
        return DataColumnsRealtimeBlacklist.HITREAL;
      // case 'col_hitreal_variance':
      //   return DataColumnsRealtimeBlacklist.HITREAL_VARIANCE;
      case 'col_clicks':
        return DataColumnsRealtimeBlacklist.CLICKS;
      case 'col_views_proc':
        return DataColumnsRealtimeBlacklist.VIEWS_PROC;
      case 'col_ctr':
        return DataColumnsRealtimeBlacklist.CTR;
      // case 'col_ctr_variance':
      //   return DataColumnsRealtimeBlacklist.CTR_VARIANCE;
      case 'col_vtr':
        return DataColumnsRealtimeBlacklist.VTR;
      // case 'col_vtr_variance':
      //   return DataColumnsRealtimeBlacklist.VTR_VARIANCE;
      case 'col_limit_spent':
        return DataColumnsRealtimeBlacklist.LIMIT_SPENT;
      case 'col_costs':
        return DataColumnsRealtimeBlacklist.COSTS;
      case 'col_cpm':
        return DataColumnsRealtimeBlacklist.CPM;
      // case 'col_cpm_variance':
      //   return DataColumnsRealtimeBlacklist.CPM_VARIANCE;
      case 'col_cpc':
        return DataColumnsRealtimeBlacklist.CPC;
      case 'col_pdp':
        return DataColumnsRealtimeBlacklist.PDP;
      case 'col_cr':
        return DataColumnsRealtimeBlacklist.CR;
      case 'col_cr_proc':
        return DataColumnsRealtimeBlacklist.CR_PROC;
      case 'col_cpa':
        return DataColumnsRealtimeBlacklist.CPA;
      // case 'col_cpa_variance':
      //   return DataColumnsRealtimeBlacklist.CPA_VARIANCE;
      case 'col_bidfloor_interval':
        return DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL;
      case 'col_conv_cost':
        return DataColumnsRealtimeBlacklist.CONV_COST;
      case 'col_koef':
        return DataColumnsRealtimeBlacklist.KOEF;
      case 'col_rate':
        return DataColumnsRealtimeBlacklist.RATE;
      case 'col_lists':
        return DataColumnsRealtimeBlacklist.LISTS;
      case 'col_helper':
        return DataColumnsRealtimeBlacklist.HELPER;
      case 'col_install':
        return DataColumnsRealtimeBlacklist.INSTALL;
      case 'col_reg':
        return DataColumnsRealtimeBlacklist.REG;
      case 'col_trader':
        return DataColumnsRealtimeBlacklist.TRADER;
      case 'col_deposit':
        return DataColumnsRealtimeBlacklist.DEPOSIT;
      case 'col_deal':
        return DataColumnsRealtimeBlacklist.DEAL;
      case 'col_min':
        return DataColumnsRealtimeBlacklist.MIN;
      case 'col_app_bundle':
        return DataColumnsRealtimeBlacklist.APP_BUNDLE;
      default:
        return null;
    }
  }

  /*export function prepare(column: DataColumnsRealtimeBlacklist, filter: CampaignRequest): TableColumn<any> {
    const result: TableColumn<any> = {
      name: DataColumnsRealtimeBlacklist.getName(column),
      selector: (campaign: any) => DataColumnsRealtimeBlacklist.getSelector(column, campaign, filter),
      sortable: true,
      maxWidth: '150px',
      style: getStyles(column),
    };

    switch (column) {
      case DataColumnsRealtimeBlacklist.TARGETING_DOMAIN:
        result.allowOverflow = true;
        result.width = '180px';
        result.cell = (campaign: any): any => {
          return (DataColumnsRealtimeBlacklist.getSelector(column, campaign));
        };
        break;
      case DataColumnsRealtimeBlacklist.NAME:
        result.wrap = true;
        result.minWidth = '200px';
        break;
      case DataColumnsRealtimeBlacklist.TRAFFIC_DYNAMIC_HOUR_CHART:
        result.cell = (campaign: any): any => {
          return (parse(DataColumnsRealtimeBlacklist.getSelector(column, campaign)));
        };
        break;
      default:
        break;
    }

    return result;
  }*/

  /*export function getStyles(column: DataColumnsRealtimeBlacklist) {
    const styles: Styles<any> = {};
    switch (column) {
      case DataColumnsRealtimeBlacklist.NAME:
        break;
      default:
        break;
    }

    return {};
  }
}*/

  export type TotalCalculatedRow = {
    id: number,
    spend: number,
    impsReal: number,
    totalRequests: number,
    wins: number,
    impressions: number,
    views: number,
    clicks: number,
    imps: number,
    trafficVtr: number,
    ctrPercent: number,
    cpc: number,
    winRate: number,
    convs: number,
    cpm: number,
    cpa: number,
    profit: number,
  };

  export function createTotalCalculatedRow(data: Campaign[]) {
    const total: TotalCalculatedRow = {
      id: -1, //total row
      clicks: 0,
      convs: 0,
      cpa: 0,
      cpc: 0,
      cpm: 0,
      ctrPercent: 0,
      impressions: 0,
      imps: 0,
      impsReal: 0,
      spend: 0,
      totalRequests: 0,
      trafficVtr: 0,
      views: 0,
      winRate: 0,
      wins: 0,
      profit: 0
    };
    data.map(c => {
      total.clicks += c.clicks;
      total.convs += c.convs;
      total.cpa += c.cpa;
      total.cpc += c.cpc;
      total.cpm += c.cpm;
      total.ctrPercent += c.ctrPercent;
      total.impressions += c.impressions;
      total.imps += c.imps;
      total.impsReal += c.impsReal;
      total.spend += c.spend;
      total.totalRequests += c.totalRequests;
      total.trafficVtr += c.trafficVtr;
      total.views += c.views;
      total.winRate += c.winRate;
      total.wins += c.wins;
      total.profit += c.profit;
    });
    return total;
  }

  export function isTotalRow(row: CampaignRealtimeBlacklistStat | TotalCalculatedRow) {
    return false;
  }
}