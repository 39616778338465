export default function setDomainCookie(name: string, value: string, days: number, domain = 'inspidsp.com'): void {
  if (window.location.host.indexOf('localhost') > -1) {
    domain = 'localhost';
  }
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = ";expires=" + date.toUTCString();
  }

  document.cookie = `${name}=${value}${expires};path=/;domain=${domain}`;
}
