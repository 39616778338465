import React, { ComponentProps, useEffect, useLayoutEffect, useRef, useState, useTransition } from 'react';
import { Campaign, CampaignRequest } from '../../api/campaigns/campaigns.get';
import { DataColumns } from '../../utils/campaigns/datatable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, createTheme, Paper, TablePagination, TableSortLabel, ThemeProvider } from '@mui/material';
import TableCell from './DataTable/TableCell';
import TableCellRaw from '@mui/material/TableCell';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TableCampaignRow from './DataTable/TableCampaignRow';
import TableTotalCalculatedRow from './DataTable/TableTotalCalculatedRow';
import CampaignToGroupModal from './CampaignToGroupModal';
import { addDays, formatDate } from '../../utils/date';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function getKeyByValue(object: any, value: any): any {
  return Object.keys(object).find(key => object[key] === value);
}


const SpacerlessTheme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          display: 'none',
        },
        displayedRows: {
          margin: 0,
        },
        selectLabel: {
          margin: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '8px',
        },
      },
    },
  },
});

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export default function(props: ComponentProps<any> & { setFilter: (filter: CampaignRequest, forceScroll: boolean) => {} }) {
  const [data, setData] = useState<Array<Campaign>>([]);
  const [columns, setColumns] = useState<DataColumns[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isOpenGroupModal, setIsOpenGroupModal] = useState<boolean>(false);
  const [campaignsChosen, setCampaignsChosen] = useState<number[]>([]);
  const [sortDir, setSortDir] = useState<SortDirection>(props.response.message.dir as SortDirection);
  const [sortColumn, setSortColumn] = useState<DataColumns | null>(parseInt(getKeyByValue(DataColumns.getSortAssociations(), props.response.message.sort)) as DataColumns);

  const [tableWidth, setTableWidth] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const adoptedScrollRef = useRef<HTMLDivElement>(null);

  const onStickyHeader = (table: HTMLTableElement) => {
    const boundRect = table.getBoundingClientRect();
    if (theadRef === null) {
      return;
    }
    if (tbodyRef.current === null) {
      return;
    }
    const tds = tbodyRef.current.querySelectorAll('tr:first-child > td');
    if (tds.length === 0) {
      return;
    }

    function makeTheadSticky() {
      theadRef.current?.classList.add('thead-sticky');
      for (let i = 0; i < tds.length; i++) {
        const ths = theadRef.current?.querySelectorAll('th');
        if (ths === null) {
          break;
        }
        // @ts-ignore
        if (ths[i] === undefined) {
          continue;
        }

        // @ts-ignore
        ths[i].style.width = String(tds[i].offsetWidth)+"px";
        // @ts-ignore
        ths[i].style.minWidth = String(tds[i].offsetWidth)+"px";
        // @ts-ignore
        ths[i].style.maxWidth = String(tds[i].offsetWidth)+"px";
      }
    }

    function clearStickyThead() {
      theadRef.current?.classList.remove('thead-sticky');
    }

    const offset = theadRef.current?.classList.contains('thead-sticky') ? theadRef.current.offsetHeight : 0;
    const top = boundRect.top - 30;
    if (top - offset < 0) {
      makeTheadSticky();
      return;
    }
    if (top > 0) {
      clearStickyThead();
      return;
    }
  };

  const handleScroll = () => {
    if (!tableRef.current) {
      return;
    }
    onStickyHeader(tableRef.current);
  };
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    const interval = setInterval(() => {
      const width = document.getElementById('campaigns-table__thead')?.offsetWidth ?? 0;
      if (width === tableWidth) {
      return;
      }
      setTableWidth(width);
    }, 100);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      clearInterval(interval);
    };
  }, []);


  const toggleModal = () => {
    setIsOpenGroupModal(!isOpenGroupModal);
  };

  function filterDataBy(str: string) {
    if (str.length === 0) {
      const campaigns = [...props.response.message.data];
      setData(campaigns);
      return;
    }
    const newData = props.response.message.data.filter((campaign: Campaign) => {
      return campaign.userLogin.toLowerCase().includes(str.toLowerCase()) || campaign.name.toLowerCase().includes(str.toLowerCase()) || campaign.id.toString().includes(str);
    });
    if (newData.length === data.length) {
      return;
    }
    setData(newData);
  }

  useEffect(() => {
    filterDataBy(props.filterBy);
  }, [props.filterBy]);
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null,
                            page: number) => {
    page += 1;
    if (Number(props.filter.page) === page) return;
    props.setFilter({ ...props.filter, page: page }, false);
  };

  const handlePerRowsChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setFilter({ ...props.filter, results: parseInt(event.target.value, 10), page: 1 }, false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: DataColumns,
  ) => {
    if (!DataColumns.getSortAssociations()[property]) {
      return;
    }
    const isDesc = sortColumn === property && sortDir === SortDirection.DESC;
    const dir = isDesc ? SortDirection.ASC : SortDirection.DESC;
    setSortDir(dir);
    setSortColumn(property);
    props.setFilter({ ...props.filter, sort: DataColumns.getSortAssociations()[property], dir: dir, page: 1 });
  };

  useEffect(() => {
    const campaigns = [...props.response.message.data];
    setData(campaigns);
  }, [props.response]);

  const createSortHandler = (property: DataColumns) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    if (data.length <= 0) {
      return;
    }
    let headers = DataColumns.getFromResponse(props.response.message);
    if (headers.length <= 0) {
      return;
    }
    setColumns(headers);
    setTotalRows(props.response.message.total);
  }, [data]);

  return (<React.Fragment>
    <div ref={adoptedScrollRef} style={{ height: '20px', overflowX: 'scroll' }} onScroll={e => {
      if (!scrollableContainerRef.current) {
        return;
      }
      scrollableContainerRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }}>
      <div style={{
        width: tableWidth,
        height: '20px',
      }}></div>
    </div>
    <TableContainer ref={scrollableContainerRef} component={Paper} onScroll={e => {
      if (adoptedScrollRef.current) {
        adoptedScrollRef.current.scrollLeft = e.currentTarget.scrollLeft;
      }
      if (theadRef.current) {
        theadRef.current.style.left = e.currentTarget.scrollLeft > 16 ? (-1 * e.currentTarget.scrollLeft +16) + 'px' : 16 + 'px';
      }
    }}>
      <ThemeProvider theme={SpacerlessTheme}>
        <Table ref={tableRef} id={'campaigns-table'} sx={{ minWidth: 650 }} aria-label='список кампаний'>
          <TableHead ref={theadRef} id={'campaigns-table__thead'}>
            <TableRow>
              <TableCellRaw className={'text-center'}>
                <Checkbox
                  onChange={(e) => {
                    setCampaignsChosen(campaignsChosen.length > 0 ? [] : data.map((campaign) => campaign.id));
                  }}
                  checked={campaignsChosen.length > 0} />
              </TableCellRaw>
              <TableCellRaw></TableCellRaw>
              <TableCell className={'text-center'}
                         columns={columns}
                         sortDirection={sortColumn === DataColumns.CREATOR ? sortDir : false}
                         targetColumn={DataColumns.CREATOR}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CREATOR}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CREATOR)}>
                  {DataColumns.getName(DataColumns.CREATOR)}
                </TableSortLabel>
              </TableCell>
              <TableCell className={'text-center'}
                         columns={columns} sortDirection={sortColumn === DataColumns.CAMPAIGN_ID ? sortDir : false}
                         targetColumn={DataColumns.CAMPAIGN_ID}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CAMPAIGN_ID}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CAMPAIGN_ID)}>
                  {DataColumns.getName(DataColumns.CAMPAIGN_ID)}
                </TableSortLabel>
              </TableCell>
              <TableCell className={'text-center'}
                         columns={columns} sortDirection={sortColumn === DataColumns.NAME ? sortDir : false}
                         targetColumn={DataColumns.NAME}>
                <TableSortLabel
                  active={sortColumn === DataColumns.NAME}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.NAME)}>
                  {DataColumns.getName(DataColumns.NAME)}
                </TableSortLabel>
              </TableCell>
              <TableCell
                         className={'text-center'}
                         columns={columns}
                         sortDirection={sortColumn === DataColumns.TYPE ? sortDir : false}
                         targetColumn={DataColumns.TYPE}>
                <TableSortLabel
                  active={sortColumn === DataColumns.TYPE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.TYPE)}>
                  {DataColumns.getName(DataColumns.TYPE)}
                </TableSortLabel>
              </TableCell>
              <TableCell
                         className={'text-center'}
                         columns={columns}
                         sortDirection={sortColumn === DataColumns.STATUS ? sortDir : false}
                         targetColumn={DataColumns.STATUS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.STATUS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.STATUS)}>
                  {DataColumns.getName(DataColumns.STATUS)}
                </TableSortLabel>
              </TableCell>
              <TableCell className={'text-center'}
                         columns={columns}
                         targetColumn={DataColumns.TARGETING_DOMAIN}>
                <TableSortLabel
                  active={sortColumn === DataColumns.TARGETING_DOMAIN}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.TARGETING_DOMAIN)}>
                  {DataColumns.getName(DataColumns.TARGETING_DOMAIN)}
                </TableSortLabel>
              </TableCell>
              <TableCell
                         className={'text-center'}
                         columns={columns}
                         targetColumn={DataColumns.RATE}>
                <TableSortLabel
                  active={sortColumn === DataColumns.RATE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.RATE)}>
                  {DataColumns.getName(DataColumns.RATE)}
                </TableSortLabel>
              </TableCell>
              <TableCell className={'text-center'}
                         columns={columns}
                         targetColumn={DataColumns.BUDGET}>
                <TableSortLabel
                  active={sortColumn === DataColumns.BUDGET}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.BUDGET)}>
                  {DataColumns.getName(DataColumns.BUDGET)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.DAILY_BUDGET}>
                <TableSortLabel
                  active={sortColumn === DataColumns.DAILY_BUDGET}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.DAILY_BUDGET)}>
                  {DataColumns.getName(DataColumns.DAILY_BUDGET)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.SPENT}>
                <TableSortLabel
                  active={sortColumn === DataColumns.SPENT}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.SPENT)}>
                  {DataColumns.getName(DataColumns.SPENT)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.POTENTIAL}>
                <TableSortLabel
                  active={sortColumn === DataColumns.POTENTIAL}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.POTENTIAL)}>
                  {DataColumns.getName(DataColumns.POTENTIAL)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.REQUESTS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.REQUESTS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.REQUESTS)}>
                  {DataColumns.getName(DataColumns.REQUESTS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.FRAUD_PERCENT}>
                <TableSortLabel
                  active={sortColumn === DataColumns.FRAUD_PERCENT}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.FRAUD_PERCENT)}>
                  {DataColumns.getName(DataColumns.FRAUD_PERCENT)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.HITS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.HITS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.HITS)}>
                  {DataColumns.getName(DataColumns.HITS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.IMPRESSIONS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.IMPRESSIONS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.IMPRESSIONS)}>
                  {DataColumns.getName(DataColumns.IMPRESSIONS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.VIEWS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.VIEWS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.VIEWS)}>
                  {DataColumns.getName(DataColumns.VIEWS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.CLICKS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CLICKS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CLICKS)}>
                  {DataColumns.getName(DataColumns.CLICKS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART}>{DataColumns.getName(DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART)}</TableCell>
              <TableCell style={{wordBreak: 'normal', overflowWrap: 'break-word', whiteSpace: 'normal'}}
                         columns={columns}
                         targetColumn={DataColumns.WIN_RATE}>
                <TableSortLabel
                  active={sortColumn === DataColumns.WIN_RATE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.WIN_RATE)}>
                  {DataColumns.getName(DataColumns.WIN_RATE)}
                </TableSortLabel>
              </TableCell>
              <TableCell style={{wordBreak: 'normal', overflowWrap: 'break-word', whiteSpace: 'normal'}}
                         columns={columns}
                         targetColumn={DataColumns.CTR_PERCENT}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CTR_PERCENT}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CTR_PERCENT)}>
                  {DataColumns.getName(DataColumns.CTR_PERCENT)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.CPC}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CPC}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CPC)}>
                  {DataColumns.getName(DataColumns.CPC)}
                </TableSortLabel>
              </TableCell>
              <TableCell style={{wordBreak: 'normal', overflowWrap: 'break-word', whiteSpace: 'normal'}}
                         columns={columns}
                         targetColumn={DataColumns.LEADS_PROFIT}>{DataColumns.getName(DataColumns.LEADS_PROFIT)}</TableCell>
              <TableCell style={{wordBreak: 'normal', overflowWrap: 'break-word', whiteSpace: 'normal'}}
                         columns={columns}
                         targetColumn={DataColumns.LEADS_LIMIT}>
                <TableSortLabel
                                active={sortColumn === DataColumns.LEADS_LIMIT}
                                direction={sortDir}
                                onClick={createSortHandler(DataColumns.LEADS_LIMIT)}>
                  {DataColumns.getName(DataColumns.LEADS_LIMIT)}
                </TableSortLabel>
              </TableCell>
              <TableCell style={{wordBreak: 'normal', overflowWrap: 'break-word', whiteSpace: 'normal'}}
                         columns={columns}
                         targetColumn={DataColumns.LIMIT_LEADS_PER_DAY}>
                <TableSortLabel
                  active={sortColumn === DataColumns.LIMIT_LEADS_PER_DAY}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.LIMIT_LEADS_PER_DAY)}>
                  {DataColumns.getName(DataColumns.LIMIT_LEADS_PER_DAY)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.LEADS}>
                <TableSortLabel
                  active={sortColumn === DataColumns.LEADS}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.LEADS)}>
                  {DataColumns.getName(DataColumns.LEADS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.PROFIT}>
                <TableSortLabel
                  active={sortColumn === DataColumns.PROFIT}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.PROFIT)}>
                  {DataColumns.getName(DataColumns.PROFIT)}
                </TableSortLabel>
                </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.RATIO}>{DataColumns.getName(DataColumns.RATIO)}</TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.CR_PERCENT}>{DataColumns.getName(DataColumns.CR_PERCENT)}</TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.CPM}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CPM}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CPM)}>
                  {DataColumns.getName(DataColumns.CPM)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns}
                         targetColumn={DataColumns.CPA}>
                <TableSortLabel
                  active={sortColumn === DataColumns.CPA}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumns.CPA)}>
                  {DataColumns.getName(DataColumns.CPA)}
                </TableSortLabel>
              </TableCell>
              <TableCell className={'action-cell'} columns={columns}
                         targetColumn={DataColumns.ACTIONS}>{DataColumns.getName(DataColumns.ACTIONS)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tbodyRef}>
            {data.map((campaign) => (
              <TableCampaignRow key={campaign.id} filter={props.filter} campaign={campaign} columns={columns}
                                campaignsChoosen={campaignsChosen}
                                checkCampaign={(campaignId: number) => setCampaignsChosen([...campaignsChosen, campaignId].filter((v, i, a) => a.indexOf(v) == i))}
                                uncheckCampaign={(campaignId: number) => setCampaignsChosen(campaignsChosen.filter((v) => v !== campaignId))} />
            ))}
            <TableTotalCalculatedRow columns={columns} row={props.response.message.totalStatistic} />
            {totalRows > 0 && <TableRow>
              <TablePagination
                sx={{
                  '.MuiTablePagination-selectLabel': {
                    margin: 0,
                  },
                  '.MuiTablePagination-displayedRows': {
                    margin: 0,
                  },
                }}
                rowsPerPageOptions={[3, 25, 50, 100, 500]}
                colSpan={columns.length}
                count={totalRows}
                rowsPerPage={props.filter.results}
                page={props.filter.page - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePerRowsChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
    <CampaignToGroupModal isOpen={isOpenGroupModal} toggle={toggleModal} campaigns={campaignsChosen}
                          groups={props.response.message.filters.groups}
                          callbackAfterSuccess={() => setCampaignsChosen([])} />
    <div
      className={'campaigns-stats-add-to-group-passer ' + (campaignsChosen.length > 0 && !isOpenGroupModal ? 'active' : '')}>
      <div onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(
          process.env.REACT_APP_OLD_ADMIN + `/statistics2?campaign[]=${campaignsChosen.join('&campaign[]=')}&GMT=0${props.filter?.systems?.join('&system[]=') ?? ''}&date_to=${formatDate(addDays(props.filter.endDate, 1))}&date_from=${props.filter.startDate}`
          , '_blank');
      }
      }
           className={'btn btn-success'}>
        смотреть статистику
      </div>
      <div onClick={() => setIsOpenGroupModal(true)} className={'btn btn-success ml-2'}>
        группировать
      </div>
      <div onClick={() => setCampaignsChosen([])} className={'btn btn-primary ml-2 mr-2'}>
        снять выделение
      </div>
      <div onClick={() => setCampaignsChosen(data.map((campaign) => campaign.id))}
           className={'btn btn-primary ml mr-2'}>
        выделить всё
      </div>
    </div>
  </React.Fragment>);
}