import React, {
  ComponentProps,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataColumns } from '../../../utils/campaigns/datatable';
import CampaignStatus from '../CampaignStatus';
import SelectTargetingDomain from '../SelectTargetingDomain';
import {
  BiBarChartAlt2,
  BiBell,
  BiCopy,
  BiFilterAlt,
  BiListUl,
  BiPencil,
  BiPlus,
  BiTrash,
  BiFastForward,
  BiCopyAlt,
} from 'react-icons/bi';
import { RiComputerFill } from 'react-icons/ri';
import gifSource from '../../../assets/img/preloader.gif';
import TableRow from '@mui/material/TableRow';
import { Campaign, CampaignStatus as CampaignStatusType } from '../../../api/campaigns/campaigns.get';
import TableCell from './TableCell';
import TableCellRaw from '@mui/material/TableCell';
import DataTableMinuteStats from '../DataTableMinuteStats';
import { AccessTime } from '@mui/icons-material';
import MaterialsStats from './MaterialsStats';
import FraudStats from './FraudStats';
import get, {
  isResponseOK,
  TelegramChatGetResponseError,
  TelegramChatGetResponseOk,
} from '../../../api/preferences/telegram-chat.get';

import subscribe, {
  CampaignSubscriptionPostResponseError,
  CampaignSubscriptionPostResponseOk,
  isResponseOK as isSubscribeResponseOk,
} from '../../../api/campaigns/campaign.subscription.post';
import appContext from '../../../utils/context/appContext';
import dashboardContext from '../../../utils/context/dashboardContext';
import unSubscribe, {
  CampaignSubscriptionDeleteResponseError,
  CampaignSubscriptionDeleteResponseOk,
  isResponseOK as isUnSubscribeResponseOk,
} from '../../../api/campaigns/campaign.subscription.delete';
import patch, { isResponseOK as isPatchCampaignResponseOK } from '../../../api/campaigns/campaigns.patch';
import { CampaignMinuteStatsResponseError } from '../../../api/campaigns/campaign.minute-stats.get';
import CampaignCopyModal from '../CampaignCopyModal';
import CampaignCopyAutoCPAModal from '../CampaignCopyAutoCPAModal';
import { Tooltip } from 'reactstrap';
import { formatDate } from '../../../utils/date';
import { Checkbox } from '@mui/material';
import getPotentialStats, {
  isResponseOK as isPotentialStatsResponseOK,
  CampaignPotentialStatsResponseError,
  CampaignPotentialStatsResponseOk,
  CampaignPotentialStats,
} from '../../../api/campaigns/campaign.potential-stats.get';
import CampaignCopyBlBundlesToAsWlModal from '../CampaignCopyBlBundlesToAsWlModal';

export enum ExpandMode {
  COLLAPSED,
  EXPAND_CAMPAIGN_CHART,
  EXPAND_MATERIAL_STATS,
  EXPAND_FRAUD_STATS
}

type TableCellType = {
  columns: DataColumns[]
  campaign: Campaign,
  mode: ExpandMode
}

function getFillColor(percent: number): string {
  if (percent <= 25) {
    return 'rgb(74, 208, 47)';
  }
  if (percent <= 50) {
    return 'rgb(133, 215, 40)';
  }
  if (percent >= 50) {
    return 'rgb(236, 142, 54)';
  }
  if (percent <= 90) {
    return 'rgb(245, 61, 61)';
  }

  return 'rgb(139, 0, 0)';
}

export default function(props: ComponentProps<any> & TableCellType & { campaignsChoosen: number[], checkCampaign: (campaignId: number) => {} }, uncheckCampaign: (campaignId: number) => {}) {
  const dataContext = useContext(appContext);
  const pageContext = useContext(dashboardContext);
  const { columns, campaign, mode } = props;
  const [expandMode, setExpandMode] = useState<ExpandMode>(ExpandMode.COLLAPSED);
  const [isUserSubscribed, setIsUserSubscribed] = useState<boolean>(campaign.isUserSubscribed);
  const [status, setStatus] = useState<CampaignStatusType>(campaign.status);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState<boolean>(false);
  const [isOpenCopyBlToWl, setIsOpenCopyBlToWl] = useState<boolean>(false);
  const [isOpenCopyAutoCPA, setIsOpenCopyAutoCPA] = useState<boolean>(false);
  const [isOpenDailyBudgetTooltip, setOpenDailyBudgetTooltip] = useState<boolean>(false);
  const [isOpenBudgetTooltip, setOpenBudgetTooltip] = useState<boolean>(false);
  const [isOpenCpaPotentialStatsTooltip, setIsOpenCpaPotentialStatsTooltip] = useState<boolean>(false);
  const [isOpenProfitPotentialStatsTooltip, setIsOpenProfitPotentialStatsTooltip] = useState<boolean>(false);
  const [isOpenConvsPotentialStatsTooltip, setIsOpenConvsPotentialStatsTooltip] = useState<boolean>(false);
  const [isOpenSpentPotentialStatsTooltip, setIsOpenSpentPotentialStatsTooltip] = useState<boolean>(false);
  const [limitCommon, setLimitCommon] = useState<number>(campaign.limitCommon);
  const [limitDaily, setLimitDaily] = useState<number>(campaign.limitDaily);
  const [limitCommonChanged, setLimitCommonChanged] = useState<boolean>(false);
  const [limitDailyChanged, setLimitDailyChanged] = useState<boolean>(false);
  const [potentialStats, setPotentialStats] = useState<CampaignPotentialStats | null>(null);
  const endDate = new Date(props.filter.endDate);
  const [budgetDailyPercent, setBudgetDailyPercent] = useState<number>((campaign.spentDay / limitDaily) * 100);
  const [budgetPercent, setBudgetPercent] = useState<number>((campaign.spentTotal / limitCommon) * 100);
  endDate.setHours(25);
  const isAdmin = pageContext.response?.message.isAdmin;


  useEffect(() => {
    if (!mode || mode === expandMode) {
      return;
    }
    setExpandMode(mode);
  }, [mode]);

  useEffect(() => {
    setBudgetDailyPercent((campaign.spentDay / limitDaily) * 100);
  }, [limitDaily]);
  useEffect(() => {
    setBudgetPercent((campaign.spentTotal / limitCommon) * 100);
  }, [limitCommon]);

  useEffect(() => {
    setLimitDaily(campaign.limitDaily);
    setLimitDailyChanged(false);
    setLimitCommon(campaign.limitCommon);
    setLimitCommonChanged(false);
    setBudgetDailyPercent((campaign.spentDay / limitDaily) * 100);
    setBudgetPercent((campaign.spentTotal / limitCommon) * 100);
    setStatus(campaign.status);
  }, [props.campaign]);

  const removeCampaign = () => {
    patch(campaign.id, { status: CampaignStatusType.ARCHIVED }).then(r => {
      if (!isPatchCampaignResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      setStatus(CampaignStatusType.ARCHIVED);
      dataContext.notify('Компания отправлена в архив', 'success');
    });
  };

  const fillPotentialStats = () => {
    getPotentialStats(campaign.id, props.filter.startDate, props.filter.endDate).then(r => {
      if (!isPotentialStatsResponseOK(r)) {
        r = r as CampaignPotentialStatsResponseError;
        dataContext.notify('Не удалось получить потенциальную статистику', 'success');
        return;
      }
      r = r as CampaignPotentialStatsResponseOk;
      setPotentialStats(r.message.data);
    });
  };

  const onClickMinuteStats = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setExpandMode(expandMode === ExpandMode.COLLAPSED ? ExpandMode.EXPAND_CAMPAIGN_CHART : ExpandMode.COLLAPSED);
  };

  const onClickFraudStats = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    setExpandMode(expandMode === ExpandMode.COLLAPSED ? ExpandMode.EXPAND_FRAUD_STATS : ExpandMode.COLLAPSED);
  };

  async function getUserTelegramChatId(): Promise<number> {
    return get().then(r => {
      if (!isResponseOK(r)) {
        r = r as TelegramChatGetResponseError;
        dataContext.notify(r.message, 'error');
        dataContext.setIsLoading(false);
        return 0;
      }

      r = r as TelegramChatGetResponseOk;
      return r.message.data.chatid;
    });
  }

  async function toggleSubscribeOnCampaign() {
    const chatid = await getUserTelegramChatId();
    if (!chatid) {
      pageContext.requestFulfilmentTelegramChatId();
      return;
    }

    if (!isUserSubscribed) subscribe(campaign.id).then(r => {
      if (!isSubscribeResponseOk(r)) {
        r = r as CampaignSubscriptionPostResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignSubscriptionPostResponseOk;
      dataContext.notify(`Вы успешно подписались на оповещения кампании ${campaign.id}`, 'success');
      setIsUserSubscribed(true);
    });
    if (isUserSubscribed) unSubscribe(campaign.id).then(r => {
      if (!isUnSubscribeResponseOk(r)) {
        r = r as CampaignSubscriptionDeleteResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignSubscriptionDeleteResponseOk;
      dataContext.notify(`Вы успешно отписались от оповещений кампании ${campaign.id}`, 'success');
      setIsUserSubscribed(false);
    });
  }

  function changeLimitCommon() {
    patch(campaign.id, { limitCommon }).then(r => {
      if (!isPatchCampaignResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      setLimitCommonChanged(false);
      dataContext.notify(`Общий лимит кампании ${campaign.id} изменён на ${limitCommon}`, 'success');
    });
  }

  function changeLimitDaily() {
    patch(campaign.id, { limitDaily }).then(r => {
      if (!isPatchCampaignResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      setLimitDailyChanged(false);
      dataContext.notify(`Дневной лимит кампании ${campaign.id} изменён на ${limitDaily}`, 'success');
    });
  }

  function filterLimitValue(val: string): number {
    val = val.replace(/[^0-9.]/g, '');

    return Number(val);
  }

  function dataCutMouseOverHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.currentTarget.innerText = e.currentTarget.dataset.base === undefined ? '' : e.currentTarget.dataset.base;
  }

  function dataCutMouseOutHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    const base = Number(e.currentTarget.dataset.base === undefined ? 0 : e.currentTarget.dataset.base);
    if (base < 1_000_000) {
      return;
    }
    e.currentTarget.innerText = e.currentTarget.dataset.cut === undefined ? '' : e.currentTarget.dataset.cut;
  }


  function goToDomainStatsPage(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    if (confirm('Перейти на новую версию страницы статистики по источникам?')) {
      window.open(`/campaigns/${campaign.id}/realtime-blacklist?startDate=${props.filter.startDate}&endDate=${props.filter.endDate}`)
    } else {
      window.open(process.env.REACT_APP_OLD_ADMIN + `/realtime_blacklist?def_domain_type=spent&def_domains_per_page=100&date_from=${props.filter.startDate}&date_to=${formatDate(endDate)}&cid=${campaign.id}&by_parts=on&domains_per_page=100&type=spent&GMT=0`, '_blank');
    }
  }
  return (<React.Fragment>
    <CampaignCopyModal campaignId={campaign.id} isOpen={isOpenCopyModal}
                       toggle={() => setIsOpenCopyModal((prev) => !prev)} />
    <CampaignCopyBlBundlesToAsWlModal campaignToId={campaign.id} isOpen={isOpenCopyBlToWl}
                                      toggle={() => setIsOpenCopyBlToWl((prev) => !prev)} />
    {isAdmin &&
      <CampaignCopyAutoCPAModal campaignId={campaign.id} isOpen={isOpenCopyAutoCPA}
                                toggle={() => setIsOpenCopyAutoCPA((prev) => !prev)} />
    }
    <TableRow
      onClick={() => setExpandMode(expandMode === ExpandMode.COLLAPSED ? ExpandMode.EXPAND_MATERIAL_STATS : ExpandMode.COLLAPSED)}
      key={campaign.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCellRaw onClick={(e) => {
        e.stopPropagation();
      }} className={'sticky-width-40'} style={{ textAlign: 'center' }}>
        <Checkbox
          onChange={(e) => {
            props.campaignsChoosen.includes(campaign.id) ? props.uncheckCampaign(campaign.id) : props.checkCampaign(campaign.id);
          }}
          checked={props.campaignsChoosen.includes(campaign.id)} />
      </TableCellRaw>
      <TableCellRaw className={'sticky-width-40'} style={{ textAlign: 'center' }}>
        <AccessTime
          onClick={(e) => onClickMinuteStats(e)} />
      </TableCellRaw>
      <TableCell className={'min-width-110'} columns={columns}
                 targetColumn={DataColumns.CREATOR}>{campaign.userLogin}</TableCell>
      <TableCell className={'min-width-55'} columns={columns}
                 targetColumn={DataColumns.CAMPAIGN_ID}>{campaign.id}</TableCell>
      <TableCell className={'sticky-width-150'} style={{ maxWidth: '200px', wordWrap: 'break-word' }} columns={columns}
                 targetColumn={DataColumns.NAME}>{campaign.name}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.TYPE}>{(campaign.format === 'push-native') ? 'PN' : campaign.format[0].toUpperCase()}</TableCell>
      <TableCell className={'status-cell min-width-85'} columns={columns} targetColumn={DataColumns.STATUS}>
        <CampaignStatus campaign={campaign} status={status} />
      </TableCell>
      <TableCell columns={columns} className={'sticky-width-150'} targetColumn={DataColumns.TARGETING_DOMAIN}>
        <SelectTargetingDomain campaign={campaign} />
      </TableCell>
      <TableCell className={'min-width-90'} columns={columns}
                 targetColumn={DataColumns.RATE}>{campaign.rate}</TableCell>
      <TableCell className={'limit-cell--with-line sticky-width-100'} columns={columns} style={{ position: 'relative' }}
                 targetColumn={DataColumns.BUDGET}>
        <div id={'BUDGET' + campaign.id} onClick={(e) => e.stopPropagation()}>
          <input id={'limit-common-change-input' + campaign.id} type='text' className={'mb-1 border-none w-100'}
                 onKeyDown={(e) => {
                   if (e.key !== 'Enter') {
                     return;
                   }
                   changeLimitCommon();
                 }}
                 value={limitCommon}
                 onChange={(e) => {
                   setLimitCommon(filterLimitValue(e.target.value));
                   setLimitCommonChanged(true);
                 }} />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              changeLimitCommon();
            }}
            className={`dynamic-save-changes btn-sm btn-success ${limitCommonChanged ? 'active' : ''}`}>сохранить
          </button>
        </div>
        <Tooltip placement='top' isOpen={isOpenBudgetTooltip} target={'BUDGET' + campaign.id}
                 toggle={() => setOpenBudgetTooltip(prev => !prev)}>
          {`${campaign.spentTotal.toFixed(2)}$ из ${limitCommon}$`}
        </Tooltip>
        <div className='limit-line'>
          <div style={{ width: budgetPercent + '%', backgroundColor: getFillColor(budgetPercent) }}
               className='limit-line__fill'></div>
        </div>
      </TableCell>
      <TableCell className={'limit-cell--with-line sticky-width-100'} columns={columns}
                 targetColumn={DataColumns.DAILY_BUDGET} style={{ position: 'relative' }}>
        <div id={'DAILY_BUDGET' + campaign.id} onClick={(e) => e.stopPropagation()}>
          <input type='text' className={'mb-1 border-none w-100'} value={limitDaily} step={0.01}
                 onKeyDown={(e) => {
                   if (e.key !== 'Enter') {
                     return;
                   }
                   changeLimitDaily();
                 }}
                 onChange={(e) => {
                   setLimitDaily(filterLimitValue(e.target.value));
                   setLimitDailyChanged(true);
                 }} />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              changeLimitDaily();
            }}
            className={`dynamic-save-changes btn-sm btn-success ${limitDailyChanged ? 'active' : ''}`}>сохранить
          </button>
        </div>
        <Tooltip placement='top' isOpen={isOpenDailyBudgetTooltip} target={'DAILY_BUDGET' + campaign.id}
                 toggle={() => setOpenDailyBudgetTooltip(prev => !prev)}>
          {`${campaign.spentDay.toFixed(2)}$ из ${limitDaily}$`}
        </Tooltip>
        <div className='limit-line'>
          <div style={{ width: budgetDailyPercent + '%', backgroundColor: getFillColor(budgetDailyPercent) }}
               className='limit-line__fill'></div>
        </div>
      </TableCell>
      <TableCell columns={columns} className={'min-width-110'}
                 targetColumn={DataColumns.SPENT}>
        <div id={'SPENT' + campaign.id} onClick={(e) => e.stopPropagation()}>
          {Number(campaign.spend.toFixed(2))}
        </div>
        <Tooltip placement='top' isOpen={isOpenSpentPotentialStatsTooltip} target={'SPENT' + campaign.id}
                 toggle={() => {
                   setIsOpenSpentPotentialStatsTooltip(prev => !prev);
                   if (potentialStats !== null) {
                     return;
                   }
                   fillPotentialStats();
                 }}>
          {potentialStats && <span>{potentialStats.spent.toFixed(2)}</span>}
          {!potentialStats && <img src={gifSource} alt='' width={20} />}
        </Tooltip>
      </TableCell>
      <TableCell columns={columns} className={'min-width-110'}
                 targetColumn={DataColumns.POTENTIAL}>{campaign.impsReal}</TableCell>
      <TableCell columns={columns} className={'min-width-100'}
                 targetColumn={DataColumns.REQUESTS}
                 data-cut={(campaign.totalRequests / 1_000_000).toFixed(1) + 'kk'}
                 data-base={campaign.totalRequests}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
      >
        {campaign.totalRequests < 1_000_000 ? campaign.totalRequests : (campaign.totalRequests / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell columns={columns} className={'sticky-width-70'}
                 targetColumn={DataColumns.FRAUD_PERCENT}></TableCell>
      <TableCell columns={columns} className={'min-width-80'}
                 data-cut={(campaign.wins / 1_000_000).toFixed(1) + 'kk'}
                 data-base={campaign.wins}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.HITS}>
        {campaign.wins < 1_000_000 ? campaign.wins : (campaign.wins / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell columns={columns} className={'min-width-90'}
                 data-cut={(campaign.impressions / 1_000_000).toFixed(1) + 'kk'}
                 data-base={campaign.impressions}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.IMPRESSIONS}>
        {campaign.impressions < 1_000_000 ? campaign.impressions : (campaign.impressions / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell columns={columns} className={'min-width-110'}
                 data-cut={(campaign.views / 1_000_000).toFixed(1) + 'kk'}
                 data-base={campaign.views}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.VIEWS}>
        {campaign.views < 1_000_000 ? campaign.views : (campaign.views / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell columns={columns} className={'min-width-80'}
                 data-cut={(campaign.clicks / 1_000_000).toFixed(1) + 'kk'}
                 data-base={campaign.clicks}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.CLICKS}>{campaign.clicks}</TableCell>
      <TableCell columns={columns} dangerouslySetInnerHTML={{ __html: campaign.trafficDynamicForHourChart }}
                 className={'sticky-width-150'} targetColumn={DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART}></TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.WIN_RATE}>{campaign.winRate}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.CTR_PERCENT}>{Number(campaign.ctrPercent.toFixed(2))}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.CPC}>{Number(campaign.cpc.toFixed(3))}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.LEADS_PROFIT}>{campaign.convCost}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.LEADS_LIMIT}>{campaign.limitConvsCommon}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.LIMIT_LEADS_PER_DAY}>{campaign.limitConvsDay}</TableCell>
      <TableCell columns={columns} className={'min-width-70'} targetColumn={DataColumns.LEADS}>
        <div id={'LEADS' + campaign.id} onClick={(e) => e.stopPropagation()}>
          {campaign.convs}
        </div>
        <Tooltip placement='top' isOpen={isOpenConvsPotentialStatsTooltip} target={'LEADS' + campaign.id}
                 toggle={() => {
                   setIsOpenConvsPotentialStatsTooltip(prev => !prev);
                   if (potentialStats !== null) {
                     return;
                   }
                   fillPotentialStats();
                 }}>
          {potentialStats && <span>{potentialStats.leads}</span>}
          {!potentialStats && <img src={gifSource} alt='' width={20} />}
        </Tooltip>
      </TableCell>
      <TableCell columns={columns} className={'min-width-80'}
                 targetColumn={DataColumns.PROFIT}>
        <div id={'PROFIT' + campaign.id} onClick={(e) => e.stopPropagation()}>
          {campaign.profit}
        </div>
        <Tooltip placement='top' isOpen={isOpenProfitPotentialStatsTooltip} target={'PROFIT' + campaign.id}
                 toggle={() => {
                   setIsOpenProfitPotentialStatsTooltip(prev => !prev);
                   if (potentialStats !== null) {
                     return;
                   }
                   fillPotentialStats();
                 }}>
          {potentialStats && <span>{potentialStats.profit.toFixed(2)}</span>}
          {!potentialStats && <img src={gifSource} alt='' width={20} />}
        </Tooltip>
      </TableCell>
      {/*<TableCell columns={columns}
                 targetColumn={DataColumns.RATIO}>{campaign.conversionRate}</TableCell>*/}
      <TableCell columns={columns} className={'min-width-50'}
                 targetColumn={DataColumns.CR_PERCENT}>{campaign.conversionRate}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.CPM}>{Number(campaign.cpm.toFixed(2))}</TableCell>
      <TableCell columns={columns} className={'min-width-70'}
                 targetColumn={DataColumns.CPA}>
        <div id={'CPA' + campaign.id} onClick={(e) => e.stopPropagation()}>
          {Number(campaign.cpa.toFixed(2))}
        </div>
        <Tooltip placement='top' isOpen={isOpenCpaPotentialStatsTooltip} target={'CPA' + campaign.id}
                 toggle={() => {
                   setIsOpenCpaPotentialStatsTooltip(prev => !prev);
                   if (potentialStats !== null) {
                     return;
                   }
                   fillPotentialStats();
                 }}>
          {potentialStats && <span>{potentialStats.cpa.toFixed(2)}</span>}
          {!potentialStats && <img src={gifSource} alt='' width={20} />}
        </Tooltip>
      </TableCell>
      <TableCell className={'action-cell sticky-width-80'} columns={columns}
                 targetColumn={DataColumns.ACTIONS}>
        <div onClick={(e) => {
          e.stopPropagation();
        }
        } style={{ whiteSpace: 'normal' }}>
          <a
            onClick={goToDomainStatsPage}
            href={process.env.REACT_APP_OLD_ADMIN + `/realtime_blacklist?def_domain_type=spent&def_domains_per_page=100&date_from=${props.filter.startDate}&date_to=${formatDate(endDate)}&cid=${campaign.id}&by_parts=on&domains_per_page=100&type=spent&GMT=0`}
            title={'Cтатистика по источникам'} target={'_blank'}>
            <BiListUl size={19} />
          </a>
          <a
            href={process.env.REACT_APP_OLD_ADMIN + `/statistics2?campaign[]=${campaign.id}&GMT=0${props.filter?.systems?.join('&system[]=') ?? ''}&date_to=${formatDate(endDate)}&date_from=${props.filter.startDate}`}
            title={'Детальная статистика по кампании'} target={'_blank'}>
            <BiBarChartAlt2 size={19} />
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickFraudStats(e);
            }}
            href={''}
            title={'Антифрод статистика'} target={'_blank'}>
            <BiFilterAlt size={19} />
          </a>
          <a
            href={process.env.REACT_APP_OLD_ADMIN + `/create_material?cid=${campaign.id}`}
            title={'Создать новый материал'} target={'_blank'}>
            <BiPlus size={19} />
          </a>
          <a
            href={process.env.REACT_APP_OLD_ADMIN + `/campaign?cid=${campaign.id}`}
            title={'Редактирование кампании'} target={'_blank'}>
            <BiPencil size={19} />
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!confirm('Вы действительно хотите скопировать РК?')) {
                return;
              }
              setIsOpenCopyModal(true);
            }
            }
            href={'#'}
            title={'Копировать кампанию'} target={'_blank'}>
            <BiCopy size={19} />
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (confirm('Вы действительно хотите удалить РК?')) {
                removeCampaign();
              }
            }}
            href={''}
            title={'Удалить кампанию'} target={'_blank'}>
            <BiTrash size={19} />
          </a>
          <NotificationIcon isUserSubscribed={isUserSubscribed} toggleSubscribeOnCampaign={toggleSubscribeOnCampaign} />
          <a
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpenCopyBlToWl(true)
            }}
            href={''}
            title={'Копирование bl источников'} target={'_blank'}>
            <BiFastForward size={22} />
          </a>
          {isAdmin &&
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenCopyAutoCPA(true)
              }}
              href={''}
              title={'Клонирование с AutoCPA'} target={'_blank'}>
              <BiCopyAlt size={22} />
            </a>
          }
        </div>
      </TableCell>
    </TableRow>
    {expandMode === ExpandMode.EXPAND_CAMPAIGN_CHART &&
      <TableRow>
        <TableCellRaw colSpan={columns.length}><DataTableMinuteStats campaignId={campaign.id} /></TableCellRaw>
      </TableRow>}
    {expandMode === ExpandMode.EXPAND_MATERIAL_STATS &&
      <TableRow>
        <TableCellRaw colSpan={columns.length}><MaterialsStats format={campaign.format}
                                                               campaignId={campaign.id} /></TableCellRaw>
      </TableRow>}
    {expandMode === ExpandMode.EXPAND_FRAUD_STATS &&
      <TableRow>
        <TableCellRaw colSpan={columns.length}><FraudStats campaignId={campaign.id} /></TableCellRaw>
      </TableRow>}
  </React.Fragment>);
}

function NotificationIcon(props: ComponentProps<any> & { isUserSubscribed: boolean, toggleSubscribeOnCampaign: () => {} }) {
  return <a
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      props.toggleSubscribeOnCampaign();
    }}
    href={'#'}
    title={'Подписка на оповещения'} target={'_blank'}>
    <BiBell style={props.isUserSubscribed ? { color: 'green' } : undefined} size={24} />
  </a>;
}