//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { AdSlots } from './ad-slot.daily-limit.patch';

const PATH_URL = '/ad-slot/ignore-ai';

export type AdSlotIgnoreAIPatchRequest = {
  adSlots: AdSlots[]
}

export type AdSlots = {
  tagid: string
  id: number
  domainId: number
  systemId: number
  app: 1 | 0,
}

export type AdSlotIgnoreAIPatchResponseOk = {
  'status': number,
  'message': AdSlotIgnoreAIPatchResponseMessageOk,
}

export type AdSlotIgnoreAIPatchResponseMessageOk = {
  data: null,
}

export type AdSlotIgnoreAIPatchResponseError = {
  'status': number,
  'message': AdSlotIgnoreAIPatchResponseMessageError,
}

export type AdSlotIgnoreAIPatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();


export default function patchIgnoreAI(request: AdSlotIgnoreAIPatchRequest): Promise<AdSlotIgnoreAIPatchResponseOk | AdSlotIgnoreAIPatchResponseError> {
  return axios.patch(process.env.REACT_APP_API_URL + PATH_URL, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AdSlotIgnoreAIPatchResponseMessageOk | AdSlotIgnoreAIPatchResponseError>r.data;
  }).catch(e => {
    const resp: AdSlotIgnoreAIPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseIgnoreAIOK(response: AdSlotIgnoreAIPatchResponseOk | AdSlotIgnoreAIPatchResponseError): boolean {
  return typeof response.message !== 'string';
}