import React from 'react';
import componentQueries from 'react-component-queries';

import { STATE_LOGIN, STATE_SIGNUP } from './components/Auth/AuthForm';
import { EmptyLayout, MainLayout } from './components/Layout';
import AuthPage from './pages/Auth/AuthPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import { apiKey } from './api/apiKey';
import './styles/inspiritum.scss';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import useApikeyRecognize from './utils/useApikeyRecognize';
import AutoCpaLogsPage from './pages/AutoCpaLogs/AutoCpaLogsPage';
import RealtimeBlacklistPage from './pages/RealtimeBlacklist/RealtimeBlacklistPage';
import CampaignAnalyticsAbsolutePage from './pages/CampaignAnalytics/CampaignAnalyticsAbsolutePage';
import CampaignAnalyticsPage from './pages/CampaignAnalytics/CampaignAnalyticsPage';
import CampaignsAlertsPage from './pages/CampaignsAlerts/CampaignsAlertsPage';
import EvaAlertsPage from './pages/Eva/Alerts/AlertsPage';
import CampaignSmartBidHistoryPage from './pages/CampaignSmartBidHistory/CampaignSmartBidHistoryPage';
import OffersPage from './pages/Offers/OffersPage';
import EvaTasksPage from './pages/Eva/Tasks/TasksPage';

const getBasename = () => {
  console.log(`/${String(process.env.REACT_APP_PUBLIC_URL).split('/').pop()}`);
  return `/${String(process.env.REACT_APP_PUBLIC_URL).split('/').pop()}`;
};

const App = (props: any) => {
  useApikeyRecognize(() => {
    // @ts-ignore
    const key = (new apiKey());
  }, []);
  return (
    <BrowserRouter basename={getBasename()}>
      <Routes>
        <Route path={'/login'} element={<EmptyLayout children={<AuthPage {...props} authState={STATE_LOGIN} />} />} />
        <Route path={'/signup'} element={<EmptyLayout children={<AuthPage {...props} authState={STATE_SIGNUP} />} />} />
        <Route element={<MainLayout />}>
          <Route path={'/'} index element={<DashboardPage />} />
          <Route path={'/campaigns/:id/realtime-blacklist'} element={<RealtimeBlacklistPage />} />
          <Route path={'/campaigns/:id/analytics'} element={<CampaignAnalyticsAbsolutePage />} />
          <Route path={'/campaigns/smartbids/history'} element={<CampaignSmartBidHistoryPage />} />
          <Route path={'/campaigns/analytics'} element={<CampaignAnalyticsPage />} />
          <Route path={'/autocpa/logs'} element={<AutoCpaLogsPage />} />
          <Route path={'/campaigns/alerts'} element={<CampaignsAlertsPage />} />
          <Route path={'/eva/alerts'} element={<EvaAlertsPage />} />
          <Route path={'/eva/tasks'} element={<EvaTasksPage />} />
          <Route path={'/offers'} element={<OffersPage />} />
        </Route>
        <Route path={'*'} element={(<Navigate to={'/'} />)} />
      </Routes>
    </BrowserRouter>
  );
};

interface width {
  width: number;
}

const query = (width: width) => {
  if (width.width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width.width && width.width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width.width && width.width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width.width && width.width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width.width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
