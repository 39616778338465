import React, { ComponentProps, useContext, useEffect, useRef, useState } from 'react';
import {
  CampaignRealtimeBlacklist,
  CampaignRealtimeBlacklistRequest,
  CampaignRealtimeBlacklistStat,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import { DataColumnsRealtimeBlacklist } from '../../utils/realtime-blacklist/datatable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, Paper, TablePagination, TableSortLabel, ThemeProvider } from '@mui/material';

import TableCell from './DataTable/TableCell';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TableRowStats from './DataTable/TableRow';
import TableTotalCalculatedRow from './DataTable/TableTotalCalculatedRow';
import TableCellRaw from '@mui/material/TableCell';
import CampaignToGroupModal from '../Dashboard/CampaignToGroupModal';
import appContext from '../../utils/context/appContext';

function getKeyByValue(object: any, value: any): any {
  return Object.keys(object).find(key => object[key] === value);
}

const SpacerlessTheme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          display: 'none',
        },
        displayedRows: {
          margin: 0,
          marginBottom: 0,
        },
        selectLabel: {
          margin: 0,
          marginBottom: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '8px',
          paddingRight: '4px',
          textAlign: 'center',
        },
      },
    },
  },
});

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}


export default function(props: ComponentProps<any> & { setFilter: (filter: CampaignRealtimeBlacklistRequest) => {} , submitFilter: boolean, setSubmitFilter: () => {} }) {
  const [data, setData] = useState<Array<CampaignRealtimeBlacklistStat>>([]);
  const [columns, setColumns] = useState<DataColumnsRealtimeBlacklist[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [sortDir, setSortDir] = useState<SortDirection>(props.response.message.dir as SortDirection);
  const [campaignData, setCampaignData] = useState<CampaignRealtimeBlacklist>(props.response.message.campaign as CampaignRealtimeBlacklist);
  const [sortColumn, setSortColumn] = useState<DataColumnsRealtimeBlacklist | null>(parseInt(getKeyByValue(DataColumnsRealtimeBlacklist.getSortAssociations(), props.response.message.sort)) as DataColumnsRealtimeBlacklist);
  const [tableWidth, setTableWidth] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const adoptedScrollRef = useRef<HTMLDivElement>(null);
  const dataContext = useContext(appContext);

  const onStickyHeader = (table: HTMLTableElement) => {
    const boundRect = table.getBoundingClientRect();
    let thead = null as HTMLElement | null;
    for (let i = 0; i < table.children.length; i++) {
      if (table.children[i].tagName === 'THEAD') {
        thead = table.children[i] as HTMLElement;
      }
    }
    if (thead === null) {
      return;
    }

    const th = thead.querySelectorAll('th');
    for (let i = 0; i < th.length; i++) {
      th[0].style.width = String(th[0].offsetWidth) + "px";
    }

    function makeTheadSticky() {
      thead?.classList.add('thead-sticky');
    }

    function clearStickyThead() {
      thead?.classList.remove('thead-sticky');
    }

    const offset = thead.classList.contains('thead-sticky') ? thead.offsetHeight : 0;
    if (boundRect.top - offset < 0) {
      makeTheadSticky();
      return;
    }
    if (boundRect.top > 0) {
      clearStickyThead();
      return;
    }
  };

  const handleScroll = () => {
    if (!tableRef.current) {
      return;
    }
    onStickyHeader(tableRef.current);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    const interval = setInterval(() => {
      const width = document.getElementById('campaigns-table__thead')?.offsetWidth ?? 0;
      if (width === tableWidth) {
        return;
      }
      setTableWidth(width);
    }, 100);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      clearInterval(interval);
    };
  }, []);

  function filterDataBy(str: string) {
    if (str.length === 0) {
      const stats = [...props.response.message.data];
      setData(stats);
      return;
    }
    const newData = props.response.message.data.filter((stat: CampaignRealtimeBlacklistStat) => {
      return stat.tagid.toLowerCase().includes(str.toLowerCase()) || String(stat.domainId).includes(str.toLowerCase()) || stat.domain.toLowerCase().includes(str.toLowerCase());
    });
    if (newData.length === data.length) {
      return;
    }
    setData(newData);
  }

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null,
                            page: number) => {
    page += 1;
    if (Number(props.filter.page) === page) return;
    props.setFilter({ ...props.filter, page: page }, false);
    props.setSubmitFilter(true);
  };

  const handlePerRowsChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setFilter({ ...props.filter, results: parseInt(event.target.value, 10), page: 1 }, false);
    props.setSubmitFilter(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: DataColumnsRealtimeBlacklist,
  ) => {
    if (!DataColumnsRealtimeBlacklist.getSortAssociations()[property]) {
      return;
    }
    const isAsc = sortColumn === property && sortDir === SortDirection.ASC;
    const dir = isAsc ? SortDirection.DESC : SortDirection.ASC;
    setSortDir(dir);
    setSortColumn(property);
    props.setFilter({
      ...props.filter,
      sort: DataColumnsRealtimeBlacklist.getSortAssociations()[property],
      dir: dir,
      page: 1,
    });
    props.setSubmitFilter(true);
  };

  useEffect(() => {
    const campaigns = [...props.response.message.data];
    setData(campaigns);
  }, [props.response]);

  const createSortHandler = (property: DataColumnsRealtimeBlacklist) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    if (data.length <= 0) {
      return;
    }
    let headers = DataColumnsRealtimeBlacklist.getFromResponse(props.response.message);
    if (headers.length <= 0) {
      return;
    }
    setColumns(headers);
    setTotalRows(props.response.message.total);
  }, [data]);

  return (<React.Fragment>
    <div ref={adoptedScrollRef} style={{ height: '20px', overflowX: 'scroll' }} onScroll={e => {
      if (!scrollableContainerRef.current) {
        return;
      }
      scrollableContainerRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }}>
      <div style={{
        width: tableWidth,
        height: '20px',
      }}></div>
    </div>
    <TableContainer ref={scrollableContainerRef} component={Paper} onScroll={e => {
      if (adoptedScrollRef.current) {
        adoptedScrollRef.current.scrollLeft = e.currentTarget.scrollLeft;
      }
      if (theadRef.current) {
        theadRef.current.style.left = e.currentTarget.scrollLeft > 16 ? (-1 * e.currentTarget.scrollLeft + 16) + 'px' : 16 + 'px';
      }
    }}>
      <ThemeProvider theme={SpacerlessTheme}>
        <Table ref={tableRef} id={'campaigns-table'} className={'bundles'} sx={{ minWidth: 650 }} aria-label='список кампаний'>
          <TableHead ref={theadRef} id={'campaigns-table__thead'}>
            <Paginate totalRows={totalRows} columns={columns} filter={props.filter} handlePageChange={handlePageChange}
                      handlePerRowsChange={handlePerRowsChange}></Paginate>
            <TableRow>
              <TableCellRaw className={'sticky-width-50'}></TableCellRaw>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.TYPE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.TYPE}>
                {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.TYPE)}
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-110'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.SYSTEM ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.SYSTEM}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.SYSTEM}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.SYSTEM)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.SYSTEM)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-110'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.PUBLISHER ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.PUBLISHER}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.PUBLISHER} className={'sticky-width-110'}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.PUBLISHER)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.PUBLISHER)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-110'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.DOMAIN_ID ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.DOMAIN_ID}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.DOMAIN_ID}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.DOMAIN_ID)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.DOMAIN_ID)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-110'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.DOMAIN ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.DOMAIN}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.DOMAIN}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.DOMAIN)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.DOMAIN)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-150'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.TAG ? sortDir : false}
                         sx={{ maxWidth: '150px' }} targetColumn={DataColumnsRealtimeBlacklist.TAG}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.TAG}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.TAG)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.TAG)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-150'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.APP_BUNDLE ? sortDir : false}
                         sx={{ maxWidth: '150px' }} targetColumn={DataColumnsRealtimeBlacklist.APP_BUNDLE}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.APP_BUNDLE)}
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.IMPS ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.IMPS}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.IMPS}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.IMPS)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.IMPS)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.IMPS_VARIANCE ? sortDir : false}*/}
              {/*           targetColumn={DataColumnsRealtimeBlacklist.IMPS_VARIANCE}>*/}
              {/*  <TableSortLabel*/}
              {/*    active={sortColumn === DataColumnsRealtimeBlacklist.IMPS_VARIANCE}*/}
              {/*    direction={sortDir}*/}
              {/*    onClick={createSortHandler(DataColumnsRealtimeBlacklist.IMPS_VARIANCE)}>*/}
              {/*    {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.IMPS_VARIANCE)}*/}
              {/*  </TableSortLabel>*/}
              {/*</TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.HITS ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.HITS}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.HITS}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.HITS)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.HITS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.HITREAL ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.HITREAL}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.HITREAL}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.HITREAL)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.HITREAL)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.HITREAL_VARIANCE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.HITREAL_VARIANCE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.HITREAL_VARIANCE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.HITREAL_VARIANCE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.HITREAL_VARIANCE)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CLICKS ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CLICKS}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CLICKS}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CLICKS)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CLICKS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.VIEWS_PROC ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.VIEWS_PROC}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.VIEWS_PROC}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.VIEWS_PROC)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.VIEWS_PROC)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CTR ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CTR}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CTR}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CTR)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CTR)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CTR_VARIANCE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CTR_VARIANCE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CTR_VARIANCE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CTR_VARIANCE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CTR_VARIANCE)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.VTR ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.VTR}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.VTR}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.VTR)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.VTR)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.VTR_VARIANCE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.VTR_VARIANCE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.VTR_VARIANCE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.VTR_VARIANCE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.VTR_VARIANCE)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.LIMIT_SPENT ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.LIMIT_SPENT}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.LIMIT_SPENT}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.LIMIT_SPENT)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.LIMIT_SPENT)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.COSTS ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.COSTS}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.COSTS}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.COSTS)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.COSTS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CPM ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CPM}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CPM}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CPM)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CPM)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CPM_VARIANCE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CPM_VARIANCE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CPM_VARIANCE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CPM_VARIANCE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CPM_VARIANCE)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CPC ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CPC}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CPC}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CPC)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CPC)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.PDP ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.PDP}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.PDP}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.PDP)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.PDP)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CR ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CR}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CR}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CR)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CR)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CR_PROC ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CR_PROC}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CR_PROC}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CR_PROC)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CR_PROC)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CPA ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CPA}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CPA}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CPA)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CPA)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CPA_VARIANCE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CPA_VARIANCE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CPA_VARIANCE}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CPA_VARIANCE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CPA_VARIANCE)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.CONV_COST ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.CONV_COST}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.CONV_COST}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.CONV_COST)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.CONV_COST)}
                </TableSortLabel>
              </TableCell>
              {/*<TableCell columns={columns} sortDirection={sortColumn === DataColumnsRealtimeBlacklist.KOEF ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.KOEF}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.KOEF}
                  direction={sortDir}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.KOEF)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.KOEF)}
                </TableSortLabel>
              </TableCell>*/}
              <TableCell columns={columns} className={'sticky-width-90'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.RATE ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.RATE}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.RATE}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.RATE)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.RATE)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.LISTS ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.LISTS}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.LISTS}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.LISTS)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.LISTS)}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={columns} className={'sticky-width-70'}
                         sortDirection={sortColumn === DataColumnsRealtimeBlacklist.MIN ? sortDir : false}
                         targetColumn={DataColumnsRealtimeBlacklist.MIN}>
                <TableSortLabel
                  active={sortColumn === DataColumnsRealtimeBlacklist.MIN}
                  direction={sortDir}
                  style={{marginLeft: "-25px"}}
                  onClick={createSortHandler(DataColumnsRealtimeBlacklist.MIN)}>
                  {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.MIN)}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalRows === 0 && <TableRow>
              <TableCellRaw colSpan={columns.length}>
                <div className={'m-2 text-center h5'}>Нет ни одной связки в статистике</div>
              </TableCellRaw>
            </TableRow>}
            {data.map((row, i) => (
              <TableRowStats key={i} filter={props.filter} row={row} columns={columns}
                             campaignId={props.response.message.campaign.id}
                             rowsChosen={props.rowsChosen}
                             setRowsChosen={props.setRowsChosen}
                             response={props.response}
                             setResponse={props.setResponse}
                             campaignData={campaignData} />
            ))}
            {totalRows > 0 && <TableTotalCalculatedRow columns={columns} row={props.response.message.totalStatistic} />}
            {totalRows > 0 && <TableRow>
              <TablePagination
                rowsPerPageOptions={[3, 25, 50, 100, 500, 5000]}
                colSpan={columns.length}
                count={totalRows}
                rowsPerPage={props.filter.results}
                page={props.filter.page - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePerRowsChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  </React.Fragment>);
}

function Paginate(props: ComponentProps<any>) {
  return (props.totalRows > 0 ? <TableRow>
    <TablePagination
      sx={{
        '.MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '.MuiTablePagination-displayedRows': {
          margin: 0,
        },
      }}
      rowsPerPageOptions={[3, 25, 50, 100, 500, 5000]}
      colSpan={props.columns.length + 1}
      count={props.totalRows}
      rowsPerPage={props.filter.results}
      page={props.filter.page - 1}
      onPageChange={props.handlePageChange}
      onRowsPerPageChange={props.handlePerRowsChange}
      ActionsComponent={TablePaginationActions}
    />
  </TableRow> : null);
}