import React, { useContext, useEffect, useState, useCallback } from 'react';
import Page from '../../components/Page';
import get, {
  OffersResponseError,
  OffersResponseOk,
  OffersData,
  CountriesData,
  CategoriesData,
  DomainsData,
  UsersData,
  OfferRequest,
  recognizeOfferRequest,
  isResponseOK, UserData,
} from '../../api/offers/offers.get';
import appContext from '../../utils/context/appContext';
import MaterialDataTable from '../../components/Offers/MaterialDataTable';
import OfferPostModal from '../../components/Offers/OfferPostModal';
import { BiPlus } from 'react-icons/bi';
import Select, { ActionMeta, OnChangeValue } from 'react-select';


const OffersPage = function() {
    const [response, setResponse] = useState<OffersResponseOk>();
    const [offers, setOffers] = useState<OffersData[]>([]);
    const [countries, setCountries] = useState<CountriesData[]>([]);
    const [categories, setCategories] = useState<CategoriesData[]>([]);
    const [domains, setDomains] = useState<DomainsData[]>([]);
    const [users, setUsers] = useState<UsersData[]>([]);
    const [filter, setFilter] = useState<OfferRequest>(recognizeOfferRequest);
    const [isOpenOfferCreateModal, setIsOpenOfferCreateModal] = useState<boolean>(false);
    const [needUpdate, isNeedUpdate] = useState<boolean>(true);
    const dataContext = useContext(appContext);
    const [viewOfferCreator, setViewOfferCreator] = useState<boolean>(false);

    const requestData = () => {
      dataContext.setIsLoading(true);
      get(filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as OffersResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as OffersResponseOk;
        setOffers(r.message.data);
        setCountries(r.message.countries);
        setCategories(r.message.categories);
        setDomains(r.message.domains);
        setUsers(r.message.users);
        setViewOfferCreator(r.message.viewOfferCreator);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    const wrapperNeedUpdate = useCallback(() => {
      isNeedUpdate(true);
    }, [isNeedUpdate]);

    useEffect(() => {
      isNeedUpdate(false);
      requestData();
    }, [needUpdate]);

  const updateByUser = (value: any) => {
    setFilter({'filteredUser': value.login});
    isNeedUpdate(true);
    return;
  };

  const clearFilter = () => {
    setFilter({});
    isNeedUpdate(true);
    return;
  }

    return (<Page
      className='Offers'
      title='Офферы'
    >
      <OfferPostModal isOpen={isOpenOfferCreateModal} domains={domains} countries={countries} categories={categories}
                      toggle={() => setIsOpenOfferCreateModal((prev) => !prev)} wrapperNeedUpdate={wrapperNeedUpdate} />
      <button type={'button'} onClick={() => setIsOpenOfferCreateModal(true)}
              className={'ml-2 btn btn-sm btn-primary mb-3'}>
        Добавить
      </button>

      {users.length > 1 && <div>
          <div className='row'>
            <div className='col-12 col-sm-4 col-md-4 col-lg-3 mb-3'>
              <Select
                name={'filteredUser'}
                isClearable={true}

                placeholder={'Все пользователи'}
                onChange={(selectedOption, triggeredAction) => {
                  if (triggeredAction.action === 'clear') {
                    clearFilter();
                  } else {
                    updateByUser(selectedOption)
                  }
                }
                }
                getOptionLabel={(option: UsersData) => option.login}
                getOptionValue={(option: UsersData) => option.login}
                options={users}></Select>
            </div>
          </div>
        </div>
      }

        <MaterialDataTable offers={offers} response={response} wrapperNeedUpdate={wrapperNeedUpdate} domains={domains}
                           countries={countries} categories={categories} viewOfferCreator={viewOfferCreator} />
    </Page>
);
}
;


export default OffersPage;

