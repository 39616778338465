import setDomainCookie from '../utils/cookies';

const apiKeyValue = 'apikey';
export function apiKey(this: any) {
  const searchKeyInCookies = () => {
    const cookies = document.cookie.split(';');
    for (let k = 0; k < cookies.length; k++) {
      const statements = cookies[k].trim().split('=');
      if (statements[0] === apiKeyValue) {
        return statements[1];
      }
    }
    return null;
  };

  this.get = () => {
    return searchKeyInCookies();
  }

  this.set = (apiKey: string) => {
    setDomainCookie(apiKeyValue, apiKey, 30);
  }
}
